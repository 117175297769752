import mixpanel from "mixpanel-browser";
import { globalAppConfig } from "./config";

if (window.location.hostname.split(".")[2] == "com") {
    mixpanel.init(globalAppConfig.MIXPANEL_API_TOKEN_PROD);
} else {
    // NOTE: If debugging mixpanel, use the dev token and enable debug mode
    mixpanel.init(globalAppConfig.MIXPANEL_API_TOKEN_DEV /*, { debug: true }*/);
}

const actions = {
    identify: (id) => {
        mixpanel.identify(id);
    },
    guest_identify: () => {
        // Does not work in incognito/private browsing
        mixpanel.identify(mixpanel.get_distinct_id());
    },
    track: (name, props) => {
        mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            mixpanel.people.set(props);
        },
    },
};

export const Mixpanel = actions;
